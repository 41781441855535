import { debounce } from 'lodash';
import router from '@/router'
import { sessionModule } from '@/store';
import { store } from '@/store/store';
import EventBus from '@/services/EventBus';
import FixProxyUrl from './FixProxyUrl';
import LoadAuthStatusAsync from '../services/AuthStatusLoader';

let idleLogoutTimer: any = null;
let inactivityWatcherInitiated = false;
let targetLogoutTime: Date | undefined;
const debugMode = window.location?.search?.includes('idleDebug')==true;

function restartLogoutTimer(logoutInSeconds: number|undefined) {
    if (logoutInSeconds == null || logoutInSeconds <0)
        logoutInSeconds = store.state.cookieExpireMinutes * 60;

    const now = new Date();
    now.setSeconds(now.getSeconds() + logoutInSeconds);
    targetLogoutTime = now;

    if (idleLogoutTimer) clearTimeout(idleLogoutTimer);
    idleLogoutTimer = setTimeout(invokeLogout, logoutInSeconds * 1000);// throttles events.

    if (debugMode)
        console.log('restartLogoutTimer(): Try to logout in ' + (logoutInSeconds / 60) + ' minutes (' + (new Date()).getMinutes() + ':' + (new Date()).getSeconds() + ')');
}

export async function invokeLogout() {

  if (debugMode)
    console.log('invokeLogout() ' + (new Date()).getMinutes() + ':' + (new Date()).getSeconds());

  window.location.href = FixProxyUrl('/logout');
  EventBus.emit('user-logged-out');
}

// This will both:  
// 1) Log the user out if they have logged out on another tab or if their session has expired, and 
// 2) Refresh the LastExternalAppActivity time, so the inactivity timer won't log out while using external applications. 
async function refreshLoginStatus() {

    if (debugMode)
        console.log('refreshLoginStatus() ' + (new Date()).getMinutes() + ':' + (new Date()).getSeconds());

    await LoadAuthStatusAsync(false, true);

    // If the user has recent external app activity, then reset the logout timer 
    if (store.state.lastExternalAppSeconds) {
        const logoutInSeconds = (store.state.cookieExpireMinutes * 60) - store.state.lastExternalAppSeconds;
        const newTargetLogoutTime = new Date();
        newTargetLogoutTime.setSeconds(newTargetLogoutTime.getSeconds() + logoutInSeconds);
        // Only extend the logout time due to external app activity if it's greater than the current logout time (potentially due to internal activity)
        if (logoutInSeconds > 0 && (!targetLogoutTime || targetLogoutTime < newTargetLogoutTime)) {
            if (debugMode)
                console.log('refreshLoginStatus(): Try to logout in ' + (logoutInSeconds / 60) + ' minutes');
            restartLogoutTimer(logoutInSeconds);
        }
    }
}


const restartTimerEventFunc = function () { restartLogoutTimer(undefined); };

export function setupInactivityRedirect() {
  if (inactivityWatcherInitiated) return;
  restartLogoutTimer(undefined);  
  ['mousemove', 'scroll', 'keypress', 'mousedown', 'click', 'touchstart'].forEach((event) => {
      document.addEventListener(event, restartTimerEventFunc);
  });

  setInterval(refreshLoginStatus, 30000);
  inactivityWatcherInitiated = true;
}

function teardownInactivityRedirect() {
  if (idleLogoutTimer != null)
    clearTimeout(idleLogoutTimer);

  ['mousemove', 'scroll', 'keypress', 'mousedown', 'click', 'touchstart'].forEach((event) => {
      document.removeEventListener(event, restartTimerEventFunc);
  });
}

export function handleLogout(redirect: string | undefined) {
  teardownInactivityRedirect()
  sessionModule.clear()
  
  if (redirect) {
    router.push({ name: redirect })
  }
}
