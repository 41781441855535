<template>
    <div class="dashboard">
        <CwePageHeader :authenticationRequired="false"/>
        <div id="MainDashboard">
            <div class="max-page-width">
                <div v-if="usersApps.length == 0" class="bottomSpaceDouble">
                    {{ $t('no_apps_enabled_for_your_account') }}
                </div>
                <div v-if="usersApps.length > 0" class="appsRow row gx-4">
                    <h5 class="header-left">{{ $t('my_zbedge_apps') }}</h5>
                    <hr class="header-line" />
                    <AppListItem v-for="app in usersApps" :key="app.name" v-bind:app="app" />
                </div>
                
                <div class="moreApps">
                    <h5 class="header-left" v-if="moreApps.length > 0">{{ $t('more_apps_to_consider') }}</h5>
                    <hr/>
                    <div v-if="moreApps.length > 0" class="moreAppsRow row gx-4">
                        <MoreAppListItem v-for="app in moreApps" :key="app.name" v-bind:app="app" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import * as Vue from 'vue';
import AppListItem from '../components/AppListItem.vue';
import MoreAppListItem from '../components/MoreAppListItem.vue';
import CwePageHeader from '../components/CwePageHeader.vue';
import AppRedirectService from '../services/AppRedirectService';
import { store } from '../store/store';

export default Vue.defineComponent({
    name: 'DashboardPage',
    components: {
        CwePageHeader,
        AppListItem,
        MoreAppListItem
    },
    beforeRouteEnter(to, from, next) {
        let apps = store.state.apps;
        var appRedirectExists = store.state.appRedirect && store.state.appRedirect.length > 0;
        var appEnabledCount = 0;
        var appVisibleCount = 0;
        var appEnabledIndex = 0;
        var appRedirectUrl = '';
        var appRedirectKey = '';
        var path = to.redirectedFrom?.query?.path?.toString();

        for (var appIndex = 0; appIndex < apps.length; appIndex++) {
            var app = apps[appIndex];
            if (app.enabled) {
                appEnabledCount++;
                appEnabledIndex = appIndex;

                if(app.visibleOnDashboard) {
                    appVisibleCount++;
                }

                if (store.state.appRedirect == app.key) {
                    appRedirectUrl = app.appRedirectUrl;
                    appRedirectKey = app.key;
                }
            }
        }

        // This is for the auto redirect if only 1 app is enabled
        // OR if there is only 1 visible app, redirect if the path was not set
        const autoRedirectEnabled = false;
		if ((appRedirectExists == false && appEnabledCount == 1 && autoRedirectEnabled) 
            || (appEnabledCount > 1 && appVisibleCount == 1 && path === undefined)) {
            let app = apps.find(a => a.enabled && a.visibleOnDashboard);
            appRedirectUrl = app?.appRedirectUrl ?? '';
            appRedirectKey = app?.key ?? '';
        }

        if (appRedirectUrl.length > 0 && appRedirectKey.length > 0) {
            if (appRedirectExists) {
                AppRedirectService.Go(appRedirectUrl, appRedirectKey, false, path);
                return;
            }

            AppRedirectService.Go(appRedirectUrl, appRedirectKey, appVisibleCount > 1, path);
            return;
        }

        next();
    },
    data() {
        return {};
    },
    computed: {
        usersApps() {
            return store.state.apps.filter(app => app.visibleOnDashboard);
        },
        moreApps() {
            return store.state.moreApps;
        }
    },
    methods: {},
    created: function () {
        
    }
});
</script>

<style lang="scss" scoped>
  hr {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: $standard_space / 2;
    margin-right: $standard_space / 2;
  }

  .header-left {
    padding-top: $standard_space;
    padding-left: $standard_space / 2;
    margin-bottom: 0px;
    align-self: flex-start;
  }

  .moreApps {
    background: $white;
  }

  .appsRow {
    margin-right: 0px;
    margin-left: 0px;
    hr {
      margin-left: 0px;
      margin-right: 0px;
    }
    .header-left {
      padding-left: 0px;
    }
  }

  .dashboard {
    background-image: url('/public/img/zbedge-diamonds-background.png');
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 50px);
  }

  @media screen and ( max-width: 660px ) {
    .dashboard {
      min-height: calc(100vh - 76px);
    }
  }

  @media screen and (min-width: 575px) {
    .max-page-width {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .moreApps, .header-line, .appsRow {
      max-width: 360px;
      min-width: 360px;
    }

    .moreAppsRow {
      min-width: 340px;
      max-width: 340px;
      margin-left: 10px;
    }
  }

  @media all and (max-width: 575px) {
    .appsRow, .moreAppsRow {
      justify-content: center;
    }

    .max-page-width {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .moreApps, .header-line, .header-left {
      max-width: 360px;
      min-width: 360px;
    }
  }

  @media screen and (min-width: 800px) {
    .appsRow {
      min-width: 760px;
      max-width: 760px;
      justify-content: flex-start;
      column-gap: 40px;
    }

    .moreApps, .header-line {
      min-width: 760px;
      max-width: 760px;
    }

    .moreAppsRow {
      min-width: 740px;
      max-width: 740px;
      justify-content: flex-start;
      margin-left: 10px;
      margin-right: 0px;
      column-gap: 20px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .appsRow, .moreAppsRow, .moreApps {
      min-width: 1160px;
      max-width: 11160px;
      justify-content: flex-start;
      column-gap: 40px;
    }

    .moreApps, .header-line {
      min-width: 1160px;
      max-width: 1160px;
    }

    .moreAppsRow {
      min-width: 1140px;
      max-width: 1140px;
      justify-content: flex-start;
      column-gap: 20px;
      margin-left: 20px;
    }
  }
</style>