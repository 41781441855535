<template>
    <div class="MoreAppListItem col ">
        <div class="col-content">
            <div class="appLogo">
                <img :src="app.iconUrl" v-bind:alt="pic">
            </div>
            <div class="appInfo">
                <div class="appName">{{ app.name }}</div>
                <div class="appDesc">{{ app.description }}</div>
                <a :href="app.learnMoreUrl" target="_blank">{{ $t('learn_more') }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import AppRedirectService from '@/services/AppRedirectService';
import * as Vue from 'vue';

export default Vue.defineComponent({
    name: 'MoreAppListItem',
    props: {
        app: Object
    },
	methods: {

    }
});
</script>


<style lang="scss">

.MoreAppListItem {
	display: inline-block;
	margin: 0 0 40px 0;
	flex: 0 0 auto;
	width: 360px;
	padding: 0;

	.col-content {
		background: #fff;
		border-radius: $element_border_radius;
		padding: 10px;
		border: 1px solid #e7e7e7;
		min-height: 210px;
		min-width: 360px;
		height: 100%;
		position: relative;
		display: flex;

		a {
			font-size: 0.9rem;
			color: $primary_color;
			font-weight: bold;
			margin-top: auto;
			cursor: pointer;
		}

		.appInfo {
			margin-top: $standard_space;
			margin-left: $standard_space * 2;
			display: flex;
			flex-direction: column;
		}

		.appLogo {
			width: 20%;
			text-align: center;
			margin-top: $standard_space;
			margin-left: $standard_space;

			img {
				width: 84px;
				height: 84px;
				border-radius: 6px;
			}
		}
	}

    .appName {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .appDesc {
        font-size: 0.9rem;
    }


    @media all and (max-width: 800px) {

        width: 340px;

        .col-content {
            min-width: 340px;

            .appLogo {
                left: 10px;
            }
        }

    }
}
</style>